body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 786px) {
  .page-login {
    width: 100% !important;
  }
}

.nav-logo.img-fluid {
  max-height: 80px !important;
}

.page-login {
  width: 35%;
  padding: 0px;
  margin-top: auto;
  margin-bottom: auto;
}

.gleamDesk-auth .form-label {
  font-size: .75rem;
  font-weight: 700;
  color: #495057;
}

.gleamDesk-auth .form-control {
  font-size: .875rem;
}